import React, { useCallback, useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalLoaderContext } from "../../context/global-loader";
import {
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  debounce,
} from "@mui/material";
import { Stack } from "@mui/system";
import Colors from "../../enums/colors";
import { Search } from "@mui/icons-material";
import PaginationActions from "../../components/utilities/pagination-actions";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../context/snackbar";
import Loader from "../../components/loader";
import { TranslationsContext } from "../../context/translations";
import { getCookie } from "../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Users() {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { jwt, user } = useContext(AuthContext);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  const { translations } = useContext(TranslationsContext);

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Users` : "Users";

  useEffect(() => {
    let isMounted = true;

    if (users) {
      return;
    }

    API.get(ApiUri.ADMIN + ApiUri.USERS, {
      params: {
        limit: rowsPerPage,
        page: page + 1,
        search: searchQuery,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then(
      (response) => {
        if (isMounted) {
          setTotalRows(response.data.totalCount);
          setUsers(response.data.data);
        }
      },
      (err) => {
        // initSnackbarError("You are not allowed to access this page.");
        history.push("/dashboard");
      }
    );

    return () => {
      isMounted = false;
    };
  }, [users]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setUsers(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setUsers(null);
  };

  const debouncedSearchTermInput = useCallback(
    debounce(() => {
      setPage(0);
      setUsers(null);
    }, 800),
    []
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleEditQuery = (event) => {
    setSearchQuery(event.target.value);
    debouncedSearchTermInput();
  };

  const rowSX = {
    "&:hover": {
      background: Colors.lighterGrey,
    },
  };

  const parseDate = (rawDate) => {
    const date = new Date(rawDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    const parsedDate = `${day}/${month}/${year}`;
    return parsedDate;
  };

  const handleRowCellClick = (selectedUser) => {
    if (selectedUser.id === user?.id) {
      history.push(`/dashboard`);
    } else {
      history.push(`/users/user/${selectedUser.id}`);
    }
  };

  return users ? (
    <React.Fragment>
      <Stack alignItems="flex-end" spacing={2}>
        <TextField
          size="small"
          // label="Search"
          value={searchQuery}
          onChange={handleEditQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ backgroundColor: Colors.lightGrey }}
        />
        <Paper sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: Colors.lightGrey }}>
                <TableRow>
                  <TableCell>
                    {translations["label.first_name"] ?? "First Name"}
                  </TableCell>
                  <TableCell>
                    {translations["label.last_name"] ?? "Last Name"}
                  </TableCell>
                  <TableCell>
                    {translations["label.email"] ?? "Email"}
                  </TableCell>
                  <TableCell>
                    {translations["label.country"] ?? "Country"}
                  </TableCell>
                  <TableCell>
                    {translations["label.language"] ?? "Language"}
                  </TableCell>
                  <TableCell>
                    {translations["table_field.last_login_at"] ??
                      "Last Login At"}
                  </TableCell>
                  <TableCell>
                    {translations["table_field.created_at"] ?? "Created At"}
                  </TableCell>
                  <TableCell>
                    {translations["table_field.apps"] ?? "Apps"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ cursor: "pointer" }}>
                {(users as any).map((row) => (
                  <TableRow key={row?.id} hover>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleRowCellClick(row)}
                    >
                      {row?.user_profile.first_name}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row?.user_profile.last_name}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row?.email}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row?.user_profile?.country?.country}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row?.user_profile?.language?.name}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {parseDate(row?.last_login)}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {parseDate(row?.created_at)}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row?.user_applications
                        .map((app) => app.application)
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </Paper>
      </Stack>
    </React.Fragment>
  ) : (
    <Loader />
  );
}
